<template>
  <div class="text-field" v-bind="rootAttrs">
    <InputLabel
      v-if="label || accessibilityLabel"
      :for="id || fallbackId"
      :value="label || accessibilityLabel"
      :required="required"
      :disabled="disabled"
      :error="error"
      :icon="labelIcon"
      class="text-field__label"
      :class="{
        'text-field__label--accessibility': accessibilityLabel,
      }"
      data-test-id="text-field-label"
    />
    <Component
      v-bind="$attrs"
      :is="inputComponent"
      :id="id || fallbackId"
      :disabled="disabled"
      :placeholder="placeholder"
      :error="error"
      :narrow="narrow"
      :name="name"
      :model-value="modelValue"
      :editor-config="editorConfig"
      :focus-on-render="focusOnRender"
      class="text-field__input"
      data-test-id="text-field-input"
      @update:model-value="$emit('update:model-value', $event)"
    />
    <InputHint
      v-if="hintText"
      :error="error"
      :value="hintText"
      class="tw-block tw-mt-1"
      data-test-id="text-field-hint"
    />
  </div>
</template>

<script>
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';
import InputLabel from '@/components/atoms/forms/InputLabel/Index.vue';
import { v4 as uuidv4 } from 'uuid';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'TextField',
  // remove compatConfig when using full vue 3 build
  compatConfig: { MODE: 3 },
  components: {
    InputHint,
    InputLabel,
    /* istanbul ignore next */
    RichTextInput: defineAsyncComponent(() =>
      import('@/components/atoms/forms/RichTextInput/Index.vue')
    ),
    /* istanbul ignore next */
    TextArea: defineAsyncComponent(() => import('@/components/atoms/forms/TextArea/Index.vue')),
    /* istanbul ignore next */
    TextInput: defineAsyncComponent(() => import('@/components/atoms/forms/TextInput/Index.vue')),
  },
  inheritAttrs: false,
  props: {
    disabled: Boolean,
    error: Boolean,
    errorMessage: String,
    id: String,
    hint: String,
    name: String,
    label: String,
    labelIcon: String,
    accessibilityLabel: String,
    placeholder: String,
    required: Boolean,
    multipleRows: Boolean,
    richText: Boolean,
    narrow: Boolean,
    focusOnRender: Boolean,
    modelValue: [String, Number],
    rootAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fallbackId: uuidv4(),
    };
  },
  computed: {
    editorConfig() {
      if (this.richText) {
        return {
          placeholder: this.placeholder,
        };
      }
      return null;
    },
    hintText() {
      return this.error && this.errorMessage ? this.errorMessage : this.hint;
    },
    inputComponent() {
      if (this.richText) {
        return 'RichTextInput';
      } else if (this.multipleRows) {
        return 'TextArea';
      } else {
        return 'TextInput';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field__label {
  display: block;
  margin-bottom: 5px;
}
.text-field__label--accessibility {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.text-field__input {
  width: 100%;

  &[disabled] {
    background-color: #eee;
    cursor: not-allowed;
  }
}
</style>
