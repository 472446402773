<script lang="ts">
export default {
  name: 'LinkTile',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ILinkTile } from '@/types/ILinkTile';
import { useTestHelpers } from '@/composables/useTestHelpers';
const { generateTestId } = useTestHelpers();

defineProps<{
  tile: ILinkTile;
  loading?: boolean;
}>();
</script>

<template>
  <a
    :href="tile.route"
    class="tw-flex tw-shadow tw-border-solid tw-border-thin tw-border-gray-500 tw-rounded-xl tw-bg-white tw-underline-none tw-text-inherit tw-p-7"
    :class="tile.theme === 'default' ? 'tw-flex-col md:tw-min-h-80' : 'md:tw-min-h-40'"
    :data-test-id="generateTestId('central report tile', tile.key)"
  >
    <template v-if="loading">
      <div class="skeleton-loading central-report-tile__tile-icon--loading"></div>
      <div class="skeleton-loading central-report-tile__tile-title--loading"></div>
      <div class="skeleton-loading small central-report-tile__tile-label--loading"></div>
    </template>
    <template v-else>
      <!-- TODO: At some point the BaseIcon needs to be refactored -->
      <component
        :is="tile.icon.startsWith('fa-') ? FontAwesomeIcon : BaseIcon"
        :icon="tile.icon.startsWith('fa-') ? `${tile.icon} fa-regular` : undefined"
        :icon-name="!tile.icon.startsWith('fa-') ? tile.icon : undefined"
        size="lg"
        class="fa-fw !tw-text-[24px] !tw-h-[44px] tw-w-[44px] tw-p-4 tw-mr-5 tw-mb-5 tw-rounded-xl"
        :style="{ color: tile.icon_color, backgroundColor: tile.icon_background_color }"
      />
      <div class="tw-flex-col" :class="{ 'tw-pl-1': tile.theme === 'default' }">
        <span class="tw-block tw-font-medium tw-text-2xl tw-mb-2">
          {{ tile.title }}
        </span>
        <span class="tw-block">
          {{ tile.description }}
        </span>
        <slot name="additional-content" />
      </div>
    </template>
  </a>
</template>
