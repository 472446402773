<script lang="ts">
export default {
  name: 'RateUserSkill',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import {
  ComponentPublicInstance,
  computed,
  defineEmits,
  defineProps,
  onMounted,
  nextTick,
  ref,
  reactive,
  watch,
} from 'vue';
import ownRateImage from 'images/skills/own_rate.svg';
import InputSlider from '@/components/atoms/InputSlider/Index.vue';
import { useI18n } from '@/composables/useI18n';
import _ from 'lodash';
import { RatingFramework } from '@/types/Skill';

interface Props {
  name?: string;
  ratingFramework: RatingFramework[];
  rating: number;
}
const props = defineProps<Props>();
const { t } = useI18n();
const emit = defineEmits(['updateRating']);

const maxDescriptionHeight = ref<number>(0);
const refs: (ComponentPublicInstance | Element | null)[] = reactive([]);
const sliderValue = ref<number>(props.rating);
const ratingDescriptionIndex = computed(() => {
  return props.rating - 1 || 0;
});

const setMaxDescriptionHeight = () => {
  maxDescriptionHeight.value =
    _.max(
      refs.map((ref) => {
        if (!ref) return 0;
        const element = ref as HTMLElement;
        const prevDisplayStyle = element.style.display;
        element.style.display = 'block';
        const height = element.offsetHeight;
        element.style.display = prevDisplayStyle;
        return height;
      })
    ) || 0;
};

watch(
  () => props.rating,
  () => {
    sliderValue.value = props.rating;
  }
);
onMounted(() => {
  nextTick(() => {
    setMaxDescriptionHeight();
  });
});
</script>

<template>
  <div class="tw-text-center tw-p-6">
    <img :src="ownRateImage" alt="" />
    <h4 class="tw-mt-12 company-text-color">{{ name }}</h4>
    <b class="tw-mt-4">{{ t('vue_templates.skills.skills_rate_multiple.rate') }}</b>
  </div>
  <div class="tw-m-6">
    <h3 class="company-text-color tw-text-center">
      {{ ratingFramework[ratingDescriptionIndex].label }}
    </h3>
    <InputSlider
      :model-value="sliderValue"
      :min="1"
      :max="ratingFramework.length"
      class="tw-mx-6"
      @update:model-value="(value: number) => emit('updateRating', value)"
    />
    <template v-for="(rate, index) in ratingFramework" :key="index">
      <div
        v-show="index === ratingDescriptionIndex"
        :ref="(el) => (refs[index] = el)"
        :style="{ 'min-height': `${maxDescriptionHeight}px` }"
        class="tw-p-4 tw-rounded-md company-summary-box"
        v-html="rate.description"
      ></div>
    </template>
  </div>
</template>
