<script lang="ts">
export default {
  name: 'ManageOpportunitiesFilters',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { ref, defineProps, watch, defineEmits } from 'vue';
import { useI18n } from '@/composables/useI18n';
import DropdownList from '@/components/atoms/DropdownList/Index.vue';
import { FilterContainer, TextFilter, SelectizeFilter } from '@/components/atoms/Filter';
import _ from 'underscore';
import { QueryParams } from '@/types/Opportunities';

defineProps<{
  usersEndpoint: string;
  skillsEndpoint: string;
  rolesEndpoint: string;
}>();

const { t } = useI18n();
const appliedFilters = ref<string[]>([]);

const filterItems = [
  { label: t('vue_templates.components.manage_opportunities_filters.title'), value: 'title' },
  { label: t('vue_templates.components.manage_opportunities_filters.added_by'), value: 'added_by' },
  { label: t('vue_templates.components.manage_opportunities_filters.skill'), value: 'skill' },
  { label: t('vue_templates.components.manage_opportunities_filters.role'), value: 'role' },
];

const addFilter = (filterValue: string) => {
  appliedFilters.value.push(filterValue);
};

const removeFilter = (filterValue: string) => {
  appliedFilters.value = appliedFilters.value.filter((f) => f !== filterValue);
};

const clearFilters = () => {
  appliedFilters.value = [];
};

const isFilterApplied = (filterValue: string) => {
  return appliedFilters.value.includes(filterValue);
};

const titleQuery = ref('');
const addedByIDsQuery = ref<number[]>([]);
const skillIDsQuery = ref<number[]>([]);
const roleIDsQuery = ref<number[]>([]);
const emit = defineEmits(['manage-opportunities-filters:queryParamsChanged']);

const onChangeAddedByQuery = (addedByIDs: number[]) => {
  addedByIDsQuery.value = addedByIDs;
};

const onChangeSkillQuery = (skillIDs: number[]) => {
  skillIDsQuery.value = skillIDs;
};

const onChangeRoleQuery = (roleIDs: number[]) => {
  roleIDsQuery.value = roleIDs;
};

const getQueryParams = () => {
  return {
    title: titleQuery.value,
    added_by_ids: addedByIDsQuery.value,
    skill_ids: skillIDsQuery.value,
    role_ids: roleIDsQuery.value,
  } as QueryParams;
};

const debouncedEmit = _.debounce((queryParams: QueryParams) => {
  emit('manage-opportunities-filters:queryParamsChanged', queryParams);
}, 1000);

watch(getQueryParams, debouncedEmit);
</script>

<template>
  <div class="manage-opportunities-filters">
    <!-- Filter list -->
    <div class="btn-group tw-mb-4">
      <DropdownList
        :selection-handler="addFilter"
        :label="t('vue_templates.activity_feed.filter_by')"
        icon="t-filter"
        :scrollable="false"
        :items="filterItems"
        alignment="left"
      />
    </div>

    <FilterContainer v-if="appliedFilters.length" :on-clear-all="clearFilters">
      <!-- Title filter -->
      <TextFilter
        v-if="isFilterApplied('title')"
        v-model="titleQuery"
        data-test-id="title-filter"
        :label="t('vue_templates.components.manage_opportunities_filters.title')"
        @filterRemoved="removeFilter('title')"
      />

      <!-- Added by filter -->
      <SelectizeFilter
        v-if="isFilterApplied('added_by')"
        data-test-id="added-by-filter"
        :label="t('vue_templates.components.manage_opportunities_filters.added_by')"
        :placeholder="
          t('vue_templates.components.manage_opportunities_filters.search_placeholder.added_by')
        "
        :sr-label="
          t('vue_templates.components.manage_opportunities_filters.search_placeholder.added_by')
        "
        :url="usersEndpoint"
        selector="addedBy"
        value-field="id"
        label-field="name"
        :max-items="20"
        :open-on-focus="true"
        :preload="true"
        :close-after-select="true"
        :on-change="onChangeAddedByQuery"
        @filterRemoved="removeFilter('added_by')"
      />

      <!-- Skill filter -->
      <SelectizeFilter
        v-if="isFilterApplied('skill')"
        data-test-id="skill-filter"
        :label="t('vue_templates.components.manage_opportunities_filters.skill')"
        :placeholder="
          t('vue_templates.components.manage_opportunities_filters.search_placeholder.skill')
        "
        :sr-label="
          t('vue_templates.components.manage_opportunities_filters.search_placeholder.skill')
        "
        :url="skillsEndpoint"
        selector="skills"
        value-field="id"
        label-field="name"
        root-key="skills"
        :max-items="20"
        :open-on-focus="true"
        :preload="true"
        :close-after-select="true"
        :on-change="onChangeSkillQuery"
        @filterRemoved="removeFilter('skill')"
      />

      <!-- Role filter -->
      <SelectizeFilter
        v-if="isFilterApplied('role')"
        data-test-id="role-filter"
        :label="t('vue_templates.components.manage_opportunities_filters.role')"
        :placeholder="
          t('vue_templates.components.manage_opportunities_filters.search_placeholder.role')
        "
        :sr-label="
          t('vue_templates.components.manage_opportunities_filters.search_placeholder.role')
        "
        :url="rolesEndpoint"
        selector="roles"
        value-field="id"
        label-field="name"
        root-key="occupations"
        :max-items="20"
        :open-on-focus="true"
        :preload="true"
        :close-after-select="true"
        :on-change="onChangeRoleQuery"
        @filterRemoved="removeFilter('role')"
      />
    </FilterContainer>
  </div>
</template>
