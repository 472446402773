<template>
  <div
    :class="{
      'tw-text-red-800': error,
    }"
    class="tw-text-[12px] tw-text-gray-600"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'InputHint',
  props: {
    error: Boolean,
    value: String,
  },
};
</script>
