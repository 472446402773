<script lang="ts">
export default {
  name: 'BaseSkillRatingModal',
  compatConfig: { MODE: 3 },
};
</script>
<script setup lang="ts">
import { defineEmits, defineProps } from 'vue';
import BaseModal from '@/components/atoms/BaseModal/Index.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import RateUserSkill from '@/components/molecules/RateUserSkill/Index.vue';
import TransitionSlideX from '@/components/atoms/Transitions/SlideX.vue';
import { useI18n } from '@/composables/useI18n';
import { UserSkill, RatingFramework } from '@/types/Skill';

interface Props {
  skill: UserSkill;
  ratingFramework: RatingFramework[];
  updateEndpoint: string;
  rating: number;
  success: boolean;
  modelValue: boolean;
}

defineProps<Props>();
const { t } = useI18n();
const emit = defineEmits(['update:model-value', 'updateRating']);

const onSliderInput = (value: number) => {
  if (!value) {
    return;
  }
  emit('updateRating', value);
};
const close = () => {
  emit('update:model-value', false);
};
</script>
<template>
  <BaseModal
    :model-value="modelValue"
    size="medium"
    :focus-trap-enabled="false"
    :show-footer="false"
    @update:model-value="close"
  >
    <template v-if="success">
      <div class="tw-text-center" data-test-id="rate-multiple-skills-modal-success">
        <h3 class="tw-my-24">{{ t('vue_templates.skills.skills_rate_multiple.success') }}</h3>
        <TransitionSlideX>
          <div class="tw-my-12">
            <FontAwesomeIcon
              icon="fa-solid fa-check"
              size="6x"
              class="tw-text-green-600"
              data-test-id="rate-multiple-skills-modal-success-icon"
            />
          </div>
        </TransitionSlideX>
        <BaseButton
          theme="brand"
          data-test-id="rate-multiple-skills-modal-done-button"
          @click.prevent="close"
          @keyup.enter.prevent="close"
          >{{ t('helpers.buttons.done') }}</BaseButton
        >
      </div>
    </template>
    <template v-else>
      <RateUserSkill
        v-if="skill"
        :name="skill?.name"
        :rating-framework="ratingFramework"
        :rating="rating"
        @updateRating="onSliderInput"
      />
      <div class="tw-m-6 tw-flex tw-justify-between">
        <slot name="footer-buttons" />
      </div>
    </template>
  </BaseModal>
</template>
