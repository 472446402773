<script lang="ts">
export default {
  name: 'SelectInput',
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';
import InputLabel from '@/components/atoms/forms/InputLabel/Index.vue';
import { SelectOption } from '@/types/SelectOptions';

const props = defineProps({
  items: {
    type: Array<SelectOption>,
    required: true,
  },
  placeholder: String,
  initialValue: [String, Number],
  labelForId: String,
  label: {
    type: String,
    require: false,
  },
  required: {
    type: Boolean,
    require: false,
  },
  hint: {
    type: String,
    require: false,
  },
  error: {
    type: Boolean,
    require: false,
  },
  errorMessage: {
    type: String,
    require: false,
  },
});

const emit = defineEmits(['select']);
const updateModel = (event) => {
  emit('select', event.target.value);
};

const value = ref(props.initialValue);
</script>

<template>
  <InputLabel v-if="label" :value="label" :required="required" :error="error" />
  <select
    v-bind="$attrs"
    :id="labelForId"
    v-model="value"
    class="form-control"
    :class="{ 'tw-outline tw-outline-1 tw-outline-red-800': error }"
    @change="updateModel"
  >
    <option v-if="placeholder" :selected="!initialValue" :value="undefined" :disabled="true">
      {{ placeholder }}
    </option>
    <option
      v-for="item in items"
      :key="item.value"
      :class="item.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'"
      :value="item.value"
      :disabled="item.disabled"
      :selected="!!(initialValue && initialValue === item.value)"
    >
      {{ item.title || item.name }}
    </option>
  </select>
  <InputHint
    v-if="hint || (error && errorMessage)"
    :error="error"
    :value="error ? errorMessage : hint"
    class="tw-mt-1 tw-px-2"
  />
</template>
