export enum QuestionType {
  MULTIPLE_CHOICE = 'multiple_choice',
  YES_NO = 'yes_no',
  TRUE_FALSE = 'true_false',
  DRAG_AND_DROP = 'drag_and_drop',
}
export default interface IQuizQuestion {
  id: number;
  quiz_id?: number;
  display_order: number;
  feedback: boolean;
  feedback_type?: string;
  question_type: 'multiple_choice' | 'yes_no' | 'true_false' | 'drag_and_drop';
  sentence: string;
  correct_feedback?: string;
  incorrect_feedback?: string;
  image: string;
  image_width: number;
  image_height: number;
  edit_url: string;
  delete_url: string;
  reorder_url: string;
  question_type_icon: string;
  question_type_label: 'Multiple choice' | 'Yes / No' | 'True / False' | 'Drag & Drop';
  answers?: IQuizAnswer[];
  dropzones?: IQuizDropzone[];
  draggables?: IQuizDraggable[];
}

interface IQuizAnswer {
  id?: number;
  question_id?: number;
  sentence?: string;
  feedback?: string;
  value?: boolean;
}

export interface IQuizDropzone {
  id?: number;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  name: string;
  capacity: number;
  at_full_capacity?: boolean;
  edit_path?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  quiz_user_draggable_assignments?: any[];
}

export interface IQuizDraggable {
  id?: number;
  x: number;
  y: number;
  width: number;
  height: number;
  name: string;
  lock_ratio: boolean;
  image: string;
  edit_path?: string;
  associated_dropzones?: Pick<IQuizDropzone, 'id' | 'name'>[];
}
