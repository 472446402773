<template>
  <div>
    <Modal
      v-if="selectedSkill"
      id="skill-description-modal"
      data-test-id="skill-description-modal"
      :header="selectedSkill.name"
    >
      <p class="m-b-2">
        {{ selectedSkill.description }}
      </p>
    </Modal>
    <CsvUploadModal
      v-model:model-value="csvUploadModalOpen"
      :title="t('vue_templates.csv_upload_modal.title', { model: learningTaxonomyTerm })"
      :upload-message="t('vue_templates.csv_upload_modal.heading', { model: learningTaxonomyTerm })"
      :template-path="csvUploadModalData.templatePath"
      :endpoint="csvUploadModalData.endpoint"
      :form-data="csvUploadModalData.formData"
      :host="csvUploadModalData.host"
      :refresh-event="csvUploadModalData.refreshEvent"
      :url="csvUploadModalData.url"
      @update:model-value="csvUploadModalOpen = $event"
    />
    <Modal
      v-if="selectedSkill"
      id="skill-users-modal"
      :key="`modal-${selectedSkill.id}`"
      :header="
        t('vue_templates.skills.skills_manage_log.users_with_skill', { skill: selectedSkill.name })
      "
    >
      <UsersSimpleList
        :filter-ongoing="false"
        :display-headers="true"
        :additional-headers="userListHeaders"
        :endpoint="skillUsersEndpoint(selectedSkill.id)"
      >
        <template #additional-table-data="slotProps">
          <AverageSkillRating
            :skill-rating-framework="skillRatingFramework"
            :average-rating="
              getAverageRating(slotProps.userProp.manager_rating, slotProps.userProp.own_rating)
            "
          />
        </template>
      </UsersSimpleList>
    </Modal>

    <div data-test-id="manage-skills-container">
      <div v-if="hasAdditionalToolbars" class="btn-toolbar manage-skills__actions-and-filters">
        <slot name="additionalToolbars" />
      </div>
      <div class="btn-toolbar manage-skills__actions-and-filters">
        <div class="btn-group dropdown">
          <!-- Action list -->
          <DropdownList
            class="manage-skills__dropdown-menu"
            data-test-id="manage-skills-action-menu"
            :selection-handler="handleActionMenu"
            :label="t('helpers.buttons.actions')"
            icon="t-settings-tools-configuration-preferences"
            :items="getActionMenuItems()"
            alignment="left"
          />
        </div>

        <div
          v-if="filters && filters.length > 0"
          class="btn-group dropdown"
          data-test-id="manage-skills-filters-button"
        >
          <!-- Filter list -->
          <DropdownList
            class="manage-skills__dropdown-menu"
            data-test-id="manage-skills-filters-menu"
            :selection-handler="addFilter"
            :label="t('vue_templates.activity_feed.filter_by')"
            icon="t-filter"
            :items="availableFilters"
            alignment="left"
          />
        </div>
        <!-- For tables with additional dropdowns i.e. batch actions -->
        <slot name="additionalActions" :pagination-data="paginationData" />
      </div>

      <!-- Primary action buttons -->
      <slot name="primaryAction" />

      <!-- Search below button list -->
      <div class="manage-skills__search-input">
        <div class="p-l-0">
          <SearchInput
            v-model="search"
            :placeholder="
              t('vue_templates.skills.skills_manage_log.search_placeholder', {
                taxonomy: learningTaxonomyTerm,
              })
            "
            class="p-l-0"
            type="discover-content"
          />
        </div>
      </div>

      <ShowingCount
        v-show="paginationData"
        :showing-count="skills.length"
        :total-count="paginationData ? paginationData.total_count : 0"
      />

      <!-- Applied filters -->
      <div v-if="showFilters" class="panel filter m-y-2">
        <div class="panel-heading filter__heading">
          {{ t('vue_templates.activity_feed.filters') }}
        </div>
        <div v-for="filter in appliedFilters" :key="filter" class="panel-body">
          <p class="m-b-0">
            <i class="ic ic-check task__note--completed"></i>
            {{ filterLabel(filter) }}
            <a
              class="filter__remove"
              tabindex="0"
              :aria-label="t('vue_templates.activity_feed.remove_filter')"
              role="button"
              data-test-id="skills-table-remove-filter-button"
              :title="t('vue_templates.activity_feed.remove_filter')"
              @click.prevent="removeFilter(filter)"
              @keyup.enter.prevent="removeFilter(filter)"
            >
              <i class="ic ic-error-cross"></i>
            </a>
          </p>
        </div>
      </div>
    </div>

    <div class="table-pretty__actions-wrapper m-y-1">
      <div class="table-pretty__wrapper" tabindex="0">
        <table class="table table-pretty">
          <thead>
            <tr>
              <slot name="prefixHeaders" />
              <!-- Skill name, sortable -->
              <TableHeader
                :heading="taxonomyHeader"
                :sticky="true"
                :sortable="true"
                :current-sort="sortKey"
                sort-key="name"
                :sort-order="sortOrder"
                :default-order="1"
                :click-handler="sortBy"
                @click="$emit('sortHeaderClick')"
              />

              <TableHeader
                class="text-center"
                :heading="t('vue_templates.skills.skills_log.learners_with_skill')"
              />

              <TableHeader
                class="text-center"
                :heading="t('vue_templates.skills.skills_log.average_skill_rating')"
              />
              <slot name="suffixHeaders" />
            </tr>
          </thead>

          <tbody
            is="vue:skeleton-table"
            v-if="loading"
            data-test-id="manage-skills-skeleton-table"
            :column-count="columnsCount"
            :cols="skeletonTableData"
          />

          <tbody v-else data-test-id="manage-skills-main-table">
            <slot name="selectAll" :pagination-data="paginationData" />

            <tr v-if="skills.length === 0" data-test-id="manage-skills-empty-table">
              <td class="text-center" :colspan="columnsCount">
                {{
                  t('vue_templates.skills.skills_manage_log.no_skill_found', {
                    taxonomy: learningTaxonomyTerm,
                  })
                }}
              </td>
            </tr>

            <tr
              v-for="skill in skills"
              v-else-if="skills.length > 0"
              :key="skill.id"
              :data-test-id="`manage-skills-data-${skill.id}`"
            >
              <slot name="prefixData" :skill="skill" />

              <td>
                <a
                  data-test-id="manage-skills-skill-link"
                  class="link--primary"
                  :href="skill.show_path"
                >
                  <span
                    v-bind="tooltipAttributes(skill.description)"
                    v-html="truncate(skill.name, truncateLength)"
                  />
                </a>
              </td>

              <td class="text-center">
                <a
                  tabindex="0"
                  role="button"
                  @click.prevent="onViewUsersClick(skill)"
                  @keyup.enter.prevent="onViewUsersClick(skill)"
                  >{{ skill.user_count }}</a
                >
              </td>

              <td class="table__column-fixed-xs">
                <AverageSkillRating
                  :tooltip-title="skill.tooltip_title"
                  :skill-rating-framework="skillRatingFramework"
                  :average-rating="getAverageRating(skill.average_rating)"
                />
              </td>

              <slot name="suffixData" :skill="skill" />
            </tr>
          </tbody>
        </table>
      </div>

      <pagination
        :pagination-data="paginationData"
        :updating="loading"
        @page-changed="handlePageChanged"
      />
    </div>
  </div>
</template>

<script>
import AverageSkillRating from '@/components/molecules/AverageSkillRating/Index.vue';
import DropdownList from '@/components/atoms/DropdownList/Index.vue';
import Modal from '@/components/atoms/Modal/Index.vue';
import SearchInput from '@/components/atoms/SearchInput/Index.vue';
import ShowingCount from '@/components/molecules/Table/ShowingCount.vue';
import TableHeader from '@/components/atoms/Table/Header.vue';
import conditionalTooltip from '@/mixins/conditionalTooltip';
import filterTruncate from '@/mixins/filterTruncate';
import i18n from '@/mixins/i18n';
import tableHelpers from '@/mixins/tableHelpers';
import tooltipToggler from '@/mixins/tooltipToggler';
import UsersSimpleList from '@/components/organisms/UsersSimpleList.vue';
import CsvUploadModal from '@/components/organisms/CsvUploadModal/Index.vue';

export default {
  name: 'TablesSkills',
  components: {
    AverageSkillRating,
    DropdownList,
    Modal,
    SearchInput,
    ShowingCount,
    TableHeader,
    UsersSimpleList,
    CsvUploadModal,
  },
  mixins: [conditionalTooltip, filterTruncate, i18n, tableHelpers, tooltipToggler],

  props: {
    endpoint: String,
    usersEndpoint: String,
    eventsEndpoint: String,
    itemsEndpoint: String,
    newSkillPath: String,
    importSkillsPath: String,
    skillCategoriesEndpoint: String,
    addToCategoryEndpoint: String,
    teamId: Number,
    groupId: Number,
    includeSubteams: Boolean,
    includeSubManagers: Boolean,
    selectedManagerId: Number,
    learningTaxonomy: String,
    // Feature toggle
    vueModalsCompatRemoval: Boolean,
    csvUploadModalData: Object,
  },

  data: function () {
    return {
      skills: [],
      skillRatingFramework: {},
      selectedSkill: undefined,
      loading: true,
      page: 1,
      per: 10,
      truncateLength: 65,
      paginationData: null,
      columnsCount: 9,
      skeletonTableData: [
        'icon',
        'text',
        'number',
        'text',
        'number',
        'text',
        'text',
        'number',
        'icon',
      ],
      search: '',
      sortKey: 'name',
      sortOrder: 1,
      userListHeaders: [this.t('activerecord.models.rating.one')],
      appliedFilters: [],
      enabled: null,
      csvUploadModalOpen: false,
    };
  },

  computed: {
    currentParams: function () {
      return {
        page: this.page,
        per: this.per,
        search: this.search,
        sort: this.sortKey,
        enabled: this.enabled,
        direction: this.sortOrder === 1 ? 'asc' : 'desc',
      };
    },
    learningTaxonomyTerm() {
      return this.learningTaxonomy || this.t('skills.terminology.topic', { count: 1 });
    },
    taxonomyHeader() {
      return _.startCase(this.learningTaxonomyTerm);
    },
    availableFilters: function () {
      return _.differenceWith(this.filters, this.appliedFilters, _.isEqual);
    },
  },

  watch: {
    search: _.debounce(function () {
      this.$emit('searchUpdated', this.search);
      this.reloadData();
    }, 500),
    teamId: function () {
      this.reloadData();
    },
    groupId: function () {
      this.reloadData();
    },
    includeSubteams: function () {
      this.reloadData();
    },
    includeSubManagers: function () {
      this.reloadData();
    },
    selectedManagerId: function () {
      this.reloadData();
    },
  },

  created: function () {
    this.getSkills();
    window.emitter.on('refresh-skills', this.getSkills);
  },

  methods: {
    hasAdditionalToolbars: function () {
      return !!this.$slots.additionalToolbars;
    },

    skillUsersEndpoint: function (selectedSkillId) {
      let url = this.usersEndpoint.replace('--skill_id--', selectedSkillId);
      if (this.teamId) {
        url = `${url}?team_id=${this.teamId}`;
      }
      return url;
    },

    onReadMoreClick: async function (skill) {
      this.selectedSkill = skill;
      await this.$nextTick();
      $('#skill-description-modal').modal('show');
    },

    onViewUsersClick: async function (skill) {
      this.selectedSkill = skill;
      await this.$nextTick();
      $('#skill-users-modal').modal('show');
    },

    getActionMenuItems: function () {
      return [
        {
          label: this.t('vue_templates.skills.skills_manage_log.import_skills', {
            taxonomy: this.learningTaxonomyTerm,
          }),
          icon: 'cloud-upload',
          ...(this.vueModalsCompatRemoval
            ? {
                value: () => {
                  this.csvUploadModalOpen = true;
                },
              }
            : { dataRemote: true, href: this.importSkillsPath }),
        },
      ];
    },

    getAverageRating: function (managerRating, ownRating = 0) {
      const rating = managerRating || ownRating || 0;
      return parseFloat(rating, 10).toFixed(2);
    },

    handleActionMenu: function (selected) {
      if (typeof selected === 'function') {
        selected();
        return;
      }
      if (typeof selected === 'string') {
        window.location.assign(selected);
      }
    },
    reloadData: async function () {
      this.page = 1;
      await this.$nextTick();
      this.getSkills();
    },

    handlePageChanged: function (newPage) {
      this.loading = true;
      this.paginationUpdating = true;
      this.page = newPage;
      this.getSkills();
      this.$emit('pageChanged');
    },

    getSkills: function () {
      this.loading = true;
      this.$http.get(this.endpoint, { params: this.currentParams }).then((response) => {
        this.skills = response.data.skills;
        this.skillRatingFramework = response.data.skill_rating_framework;
        this.paginationData = response.data.pagination;
        this.loading = false;
        this.updating = false;
        this.refreshTooltips();
      });
    },
    addFilter: function (filter) {
      this.appliedFilters.push(filter);
      switch (filter) {
        case 'enabled':
          this.removeFilter('disabled', false);
          this.enabled = 'yes';
          break;
        case 'disabled':
          this.removeFilter('enabled', false);
          this.enabled = 'no';
          break;
      }
      this.reloadData();
    },
    filterLabel: function (appliedFilter) {
      const index = this.filters.map((filter) => filter.value).indexOf(appliedFilter);
      if (index === -1) {
        return;
      } else {
        return this.filters[index].label;
      }
    },
    removeFilter: function (filter, reload = true) {
      const index = this.appliedFilters.indexOf(filter);
      if (index === -1) {
        return;
      }
      switch (filter) {
        case 'enabled':
          this.enabled = null;
          break;
        case 'disabled':
          this.enabled = null;
          break;
      }

      this.appliedFilters.splice(index, 1);
      if (reload) {
        this.reloadData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.company-skills__skill-description {
  width: 100%;
  display: block;
  white-space: normal;
  word-wrap: break-word;
}

.manage-skills__actions-and-filters {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  margin-bottom: 14px;
}

.manage-skills__search-input {
  display: block;
  width: 100%;
}
</style>
