import { ref, Ref } from 'vue';

export function useSkillRating() {
  const showRatingSuccess: Ref<boolean> = ref(false);

  const onSkillUpdated = () => {
    window.emitter.emit('user-skill-updated');
  };

  return {
    showRatingSuccess,
    onSkillUpdated,
  };
}
