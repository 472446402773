<script lang="ts">
export default {
  name: 'SettingsMenu',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineEmits, defineProps, computed, ref, Ref } from 'vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import PopoverMenuItem from '@/components/atoms/Popover/PopoverMenuItem/Index.vue';
import PopoverMenuLink from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuLink.vue';
/*
  [LA-23809] Commented out for implementation of popover subnav
  Potentially still usable; Completely remove when applicable
*/
// import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import { SettingsMenu } from '@/json_schema_types/sidebar_navigation';

const props = defineProps<{ settings: SettingsMenu; collapsed: boolean; highlighted?: boolean }>();
const emit = defineEmits(['menuOpened']);
const sideNavigationSettingsButton = ref<HTMLElement | null>(null);
const menuOpen: Ref<boolean> = ref(false);
const faIconString = computed(() => {
  return menuOpen.value || props.highlighted
    ? `fa-solid ${props.settings.icon}`
    : `fa-regular ${props.settings.icon}`;
});
const toggleMenuOpen = () => {
  menuOpen.value = !menuOpen.value;
  if (menuOpen.value) {
    emit('menuOpened');
  }
};
</script>

<template>
  <li class="settings-menu">
    <button
      ref="sideNavigationSettingsButton"
      aria-label="Click to open settings menu"
      aria-haspopup="true"
      :aria-expanded="menuOpen"
      aria-controls="settings-popover-menu"
      data-test-id="settings-menu-button"
      :class="[
        'side-navigation__settings-button',
        { 'side-navigation__settings-button--highlighted': props.highlighted },
        { 'side-navigation__settings-button--focused': menuOpen && !props.highlighted },
      ]"
      @click.prevent="toggleMenuOpen"
      @keyup.enter="toggleMenuOpen"
    >
      <FontAwesomeIcon :icon="faIconString" size="lg" class="fa-fw" />
      <span :class="{ 'visually-hidden': collapsed }"> {{ settings?.label }} </span>
    </button>
    <Teleport to=".vue--sidebar-navigation">
      <PopoverMenu
        v-if="menuOpen"
        id="settings-popover-menu"
        position="top"
        max-width="200px"
        :toggle-element-selector="sideNavigationSettingsButton"
        :style="{
          borderRadius: '1rem',
          left: collapsed
            ? 'calc(var(--side-navigation-width) + 1rem)'
            : 'calc(var(--side-navigation-width-expanded-dynamic) + 1rem)',
          bottom: '0',
        }"
        :model-value="menuOpen"
        @update:model-value="menuOpen = $event"
      >
        <!--
          [LA-23809] Commented out for implementation of popover subnav
          Potentially still usable; Completely remove when applicable
        -->
        <!-- <BaseButton
          fa-prepend-icon="fa-solid fa-arrow-left"
          fa-icon-size="sm"
          variant="icon"
          data-test-id="settings-menu-close-button"
          class="settings-popover-menu__close-button"
          @click.prevent="menuOpen = false"
        /> -->
        <PopoverMenuItem padding="0.5rem 2.5rem">
          <span class="settings-popover-menu__header h5">{{ settings.label }}</span>
        </PopoverMenuItem>
        <template
          v-for="(item, itemIndex) in settings.children"
          :key="`settings-menu-item-${itemIndex}`"
        >
          <PopoverMenuItem :id="itemIndex" padding="0.5rem 2rem"
            ><PopoverMenuLink
              :item="item"
              :custom-styles="{ color: 'var(--navigation-text-color)', fontSize: '1.4rem' }"
              is-nav
          /></PopoverMenuItem>
        </template>
      </PopoverMenu>
    </Teleport>
  </li>
</template>

<style lang="scss" scoped>
.settings-menu {
  color: inherit;
}

#settings-popover-menu {
  background-color: var(--navigation-color);
}

#settings-popover-menu,
.settings-popover-menu__header,
.settings-popover-menu__close-button {
  color: var(--navigation-text-color);
}

.settings-popover-menu__close-button {
  all: unset;
  cursor: pointer;
  margin: 2rem 0.5rem 0;
  padding: 0.5rem 1.25rem;
}

.side-navigation__settings-button {
  &:hover,
  &--focused {
    background-color: var(--navigation-hover-color);
  }

  &--highlighted,
  &--highlighted:hover {
    background-color: var(--navigation-highlight-color);
    font-weight: bold;
  }
}
</style>
