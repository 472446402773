<script lang="ts">
export default {
  name: 'TileContainer',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import LinkTile from '@/components/molecules/LinkTile/Index.vue';
import { ILinkTile, TileLabelType } from '@/types/ILinkTile';
import pluralizeWord from '@/helpers/pluralize-word';

interface Props {
  heading?: string;
  tiles: ILinkTile[];
}

const props = defineProps<Props>();
const smallTiles = computed(() => props.tiles.some((tile) => tile.theme === 'small'));
const containerClass = computed(() =>
  smallTiles.value ? 'md:tw-grid-cols-3' : 'md:tw-grid-cols-4'
);

const getLabelColors = (label: TileLabelType) => {
  const colorMap: Record<string, string> = {
    Overdue: 'tw-bg-red-100 tw-text-red-600',
    Draft: 'tw-bg-yellow-100 tw-text-orange-950',
  };
  return colorMap[label] || 'tw-bg-gray-200 tw-text-gray-600';
};
</script>

<template>
  <h1 v-if="heading" class="tw-text-3xl tw-mb-6 tw-pl-2 tw-font-semibold">
    {{ heading }}
  </h1>

  <div class="tw-grid tw-gap-12 tw-grid-cols-1" :class="containerClass">
    <LinkTile v-for="(tile, index) in tiles" :key="index" :tile="tile" :loading="false">
      <template v-if="tile.tile_labels?.length" #additional-content>
        <span
          v-for="(label, labelIndex) in tile.tile_labels"
          :key="labelIndex"
          :class="[
            'tw-text-xs tw-rounded-full tw-py-1 tw-px-2 tw-mr-2',
            getLabelColors(label.label),
          ]"
        >
          {{ pluralizeWord(label.label, label.label, label.count) }}
        </span>
      </template>
    </LinkTile>
  </div>
</template>
